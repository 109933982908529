// Input: "+12063506027" / "+447400000000"
// Output: "(206) 350-6027" / "07400 000000"
export function formatPhoneNumber(phoneNumberString) {
  const cleaned = phoneNumberString.replace(/\D/g, '');

  if ((cleaned.length === 11 && cleaned.startsWith('1')) || cleaned.length === 10) {
    const usMatch = cleaned.match(/1?(\d{3})(\d{3})(\d{4})$/);
    if (usMatch) {
      return `(${usMatch[1]}) ${usMatch[2]}-${usMatch[3]}`;
    }
  } else if ((cleaned.length === 12 && cleaned.startsWith('44')) || (cleaned.length === 11 && cleaned.startsWith('0'))) {
    let nationalNumber = cleaned;
    if (cleaned.startsWith('44')) {
      nationalNumber = '0' + cleaned.slice(2);
    }
    const ukMatch = nationalNumber.match(/^0(\d{4})(\d{6})$/);
    if (ukMatch) {
      return "0" + `${ukMatch[1]} ${ukMatch[2]}`;
    }
  }

  return null;
}

// Input: "+12063506027" / "+447400000000"
// Output: "2063506027" / "07400000000"
export const formatPhoneNumberWithoutCountryCode = (phoneNumber) => {
  if (phoneNumber.startsWith('+1')) {
    return phoneNumber.slice(2);
  } else if (phoneNumber.startsWith('+44')) {
    return "0" + phoneNumber.slice(3);
  }
  return phoneNumber;
};
  